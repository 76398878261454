<template>
  <div class="pool" style="">
    <div style="width: 100%; height: 70%; position: absolute; z-index: -1">
      <img src="../static/images/pool_bg.png" width="100%" style="" />
    </div>
    <div style="height: 3.125rem"></div>
    <div
      style="
        width: 84%;
        margin: 0 auto;
        padding-left: 3%;
        padding-right: 2%;
        height: 5rem;
        background-color: #0f0f50;
        border-radius: 3.125rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      "
    >
      <div style="height: 70%; display: flex; align-items: center">
        <img src="../assets/logo.png" style="height: 100%" />
        <div style="margin-left: 0.3125rem">
          <span style="color: #bcbcbc">{{ $t("got") }}LS:</span>
          <span style="color: #42bae4; font-weight: bold; font-size: 1.2rem">{{
            earned
          }}</span>
        </div>
      </div>
      <div
        @click="getReward"
        class="button"
        style="width: 5rem; height: 2rem; line-height: 2rem"
      >
        {{ $t("get") }}
      </div>
    </div>

    <div style="width: 90%; margin: 0 auto; margin-top: 1.875rem">
      <div style="display: flex; align-items: center">
        <img src="../static/images/hoe.png" width="18px" />
        <span style="color: #47b3e1; margin-left: 0.3125rem"
          >{{ $t("pledge_dig_compound_mode") }}:</span
        >
      </div>
      <div style="height: 1rem"></div>
      <!-- <div v-for="(item,index) in poolInfo.lps" class="pull unfold"
				:style="{height:active == item.lpName ? 'auto' : ''}"> -->
      <div
        :key="index"
        v-for="(item, index) in poolInfo.lps"
        class="pull"
        :class="active == index ? 'unfold' : ''"
      >
        <div
          @click="unfold(index)"
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div>STAR LP / {{ item.lpName }}</div>
          <div style="display: flex; align-items: center">
            <span style="font-size: 1rem; margin-right: 0.3125rem"
              >{{
                item.starAmount == null
                  ? "0"
                  : BigNumberToFloat(
                      item.starAmount,
                      poolInfo.pledgeTokenDecimals,
                      0
                    )
              }}
              /
              {{
                item.customAmount == null
                  ? "--"
                  : BigNumberToFloat(
                      item.customAmount,
                      poolInfo.pledgeTokenDecimals,
                      0
                    )
              }}</span
            >
            <img
              v-show="index != active"
              src="../static/images/arrow.png"
              width="8px"
            />
            <img
              v-show="index == active"
              src="../static/images/arrow_bottom.png"
              width="12px"
            />
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-around;
            margin-top: 0.625rem;
          "
        >
          <div
            style="
              width: 45%;
              background-color: #0a0649;
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 8rem;
              justify-content: space-around;
            "
          >
            <span style="color: #dbdbe4; font-size: 0.875rem">{{
              $t("pledged")
            }}</span>
            <span style="color: #dbdbe4">STAR LP</span>
            <h3 style="color: #42bae4">
              {{
                item.starBalance == null
                  ? "--"
                  : BigNumberToFloat(
                      item.starBalance,
                      poolInfo.pledgeTokenDecimals,
                      4
                    )
              }}
            </h3>
            <div
              v-if="!starIsApprove"
              @click="approve(poolInfo.pledgeTokenAddress)"
              style="
                height: 1.5rem;
                background-color: #3da9d5;
                text-align: center;
                line-height: 1.5rem;
                border-radius: 1.25rem;
                padding: 0 0.625rem;
              "
            >
              {{ $t("approve") }}
            </div>
            <div
              v-if="starIsApprove"
              style="
                height: 1.5rem;
                background-color: #3da9d5;
                text-align: center;
                line-height: 1.5rem;
                border-radius: 1.25rem;
                padding: 0 0.625rem;
              "
            >
              {{ $t("approved") }}
            </div>
          </div>
          <div
            style="
              width: 45%;
              background-color: #0a0649;
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 8rem;
              justify-content: space-around;
            "
          >
            <span style="color: #dbdbe4; font-size: 0.875rem">{{
              $t("pledged")
            }}</span>
            <span style="color: #dbdbe4">{{ item.lpName }}</span>
            <h3 style="color: #42bae4">
              {{
                item.customBalance == null
                  ? "--"
                  : BigNumberToFloat(
                      item.customBalance,
                      poolInfo.pledgeTokenDecimals,
                      4
                    )
              }}
            </h3>
            <div
              v-if="!item.isApprove"
              @click="approve(item.lpAddress, index)"
              style="
                height: 1.5rem;
                background-color: #3da9d5;
                text-align: center;
                line-height: 1.5rem;
                border-radius: 1.25rem;
                padding: 0 0.625rem;
              "
            >
              {{ $t("approve") }}
            </div>

            <div
              v-if="item.isApprove"
              style="
                height: 1.5rem;
                background-color: #3da9d5;
                text-align: center;
                line-height: 1.5rem;
                border-radius: 1.25rem;
                padding: 0 0.625rem;
              "
            >
              {{ $t("approved") }}
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-around;
            margin-top: 0.625rem;
          "
        >
          <div
            @click="showDeposit = true"
            class="button"
            style="
              width: 45%;
              height: 2.5rem;
              line-height: 2.5rem;
              color: #42bae4;
            "
          >
            {{ $t("pledge") }}
          </div>
          <div
            @click="showWithdraw = true"
            class="button"
            style="
              width: 45%;
              height: 2.5rem;
              line-height: 2.5rem;
              color: #42bae4;
            "
          >
            {{ $t("redeem") }}
          </div>
        </div>
      </div>
    </div>
    <div style="height: 1.25rem"></div>
    <van-overlay :show="showLoading">
      <div
        style="width: 100%; display: flex; align-items: center; height: 100%"
      >
        <div
          style="
            padding: 20px 0;
            width: 90%;
            background-color: #ffffff;
            margin: 0 auto;
            box-shadow: 10px 10px 10px #000000;
            border-radius: 0.625rem;
            display: flex;
            align-items: center;
            flex-direction: column;
          "
          @click.stop
        >
          <van-loading color="#272727" size="80px" style="margin: 20px 0" />
          <p style="font-size: 1.125rem">{{ note }}</p>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showDeposit">
      <div
        style="
          width: 94%;
          height: 180px;
          background-color: #ffffff;
          position: fixed;
          bottom: 0;
          border-radius: 10px 10px 0px 0px;
          box-shadow: #ccc 0px 0px 8px;
          padding: 3%;
        "
        @click.stop
      >
        <h3 style="color: rgb(15, 15, 80); text-align: center">
          {{ $t("pledge") }}
        </h3>

        <div style="width: 90%; margin: 0.625rem auto; display: flex">
          <p
            style="
              color: rgb(50, 77, 86);
              font-size: 14px;
              margin-top: 10px;
              flex: 1;
            "
          >
            {{
              pledgeBalance == null
                ? pledgeBalance
                : BigNumberToFloat(
                    pledgeBalance,
                    poolInfo.pledgeTokenDecimals,
                    0
                  )
            }}
            STAR LP {{ $t("available_pledge") }}
          </p>
          <p
            style="
              color: rgb(50, 77, 86);
              font-size: 14px;
              margin-top: 10px;
              flex: 1;
              text-align: right;
            "
          >
            {{
              active == -1
                ? null
                : poolInfo.lps[active].balance == null
                ? null
                : BigNumberToFloat(
                    poolInfo.lps[active].balance,
                    poolInfo.pledgeTokenDecimals,
                    0
                  ) +
                  " " +
                  poolInfo.lps[active].lpName
            }}
            {{ $t("available_pledge") }}
          </p>
        </div>
        <div style="width: 100%; margin-top: 0.625rem; display: flex">
          <van-field
            type="number"
            input-align="center"
            style="flex: 1"
            v-model="depositStarAmount"
            :placeholder="$t('please_input') + 'STAR LP'"
          />
          <van-field
            type="number"
            disabled
            input-align="center"
            style="flex: 1"
            v-model="depositCustomAmount"
            :placeholder="
              active != -1 ? $t('need') + poolInfo.lps[active].lpName : ''
            "
          />
        </div>
        <div style="display: flex">
          <div
            @click="showDeposit = false"
            class="button"
            style="
              line-height: 30px;
              font-weight: bold;
              height: 30px;
              width: 40%;
              margin: 0 auto;
              margin-top: 20px;
              cursor: pointer;
            "
          >
            {{ $t("cancel") }}
          </div>
          <div
            class="button"
            @click="deposit"
            style="
              line-height: 30px;
              font-weight: bold;
              height: 30px;
              width: 40%;
              margin: 0 auto;
              margin-top: 20px;
              cursor: pointer;
            "
          >
            {{ $t("pledge") }}
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showWithdraw">
      <div
        style="
          width: 94%;
          height: 180px;
          background-color: #ffffff;
          position: fixed;
          bottom: 0;
          border-radius: 10px 10px 0px 0px;
          box-shadow: #ccc 0px 0px 8px;
          padding: 3%;
        "
        @click.stop
      >
        <h3 style="color: rgb(15, 15, 80); text-align: center">
          {{ $t("redeem") }}
        </h3>

        <div style="width: 90%; margin: 0.625rem auto; display: flex">
          <p
            style="
              text-align: right;
              color: rgb(50, 77, 86);
              font-size: 14px;
              margin-top: 10px;
            "
          >
            {{
              active != -1 && poolInfo.lps[active].starBalance != null
                ? BigNumberToFloat(
                    poolInfo.lps[active].starBalance,
                    poolInfo.pledgeTokenDecimals,
                    4
                  )
                : ""
            }}
            STAR LP {{ $t("available_redeem") }}
          </p>
          <p
            style="
              color: rgb(50, 77, 86);
              font-size: 14px;
              margin-top: 10px;
              flex: 1;
              text-align: right;
            "
          >
            {{
              active == -1
                ? null
                : poolInfo.lps[active].customBalance == null
                ? null
                : BigNumberToFloat(
                    poolInfo.lps[active].customBalance,
                    poolInfo.pledgeTokenDecimals,
                    0
                  ) +
                  " " +
                  poolInfo.lps[active].lpName
            }}
            {{ $t("available_redeem") }}
          </p>
        </div>

        <div style="width: 100%; margin-top: 0.625rem; display: flex">
          <van-field
            type="number"
            input-align="center"
            style="flex: 1"
            v-model="withdrawStarAmount"
            :placeholder="$t('please_input') + ' STAR LP'"
          />
          <van-field
            type="number"
            disabled
            input-align="center"
            style="flex: 1"
            v-model="withdrawCustomAmount"
            :placeholder="
              active != -1
                ? $t('available_redeem') + poolInfo.lps[active].lpName
                : ''
            "
          />
        </div>
        <div style="display: flex">
          <div
            @click="showWithdraw = false"
            class="button"
            style="
              line-height: 30px;
              font-weight: bold;
              height: 30px;
              width: 40%;
              margin: 0 auto;
              margin-top: 20px;
              cursor: pointer;
            "
          >
            {{ $t("cancel") }}
          </div>
          <div
            class="button"
            @click="withdraw"
            style="
              line-height: 30px;
              font-weight: bold;
              height: 30px;
              width: 40%;
              margin: 0 auto;
              margin-top: 20px;
              cursor: pointer;
            "
          >
            {{ $t("redeem") }}
          </div>
        </div>
      </div>
    </van-overlay>

    <van-overlay :show="showMapping">
      <div
        style="
          width: 94%;
          height: 140px;
          background-color: #ffffff;
          position: fixed;
          bottom: 0;
          border-radius: 10px 10px 0px 0px;
          box-shadow: #ccc 0px 0px 8px;
          padding: 3%;
        "
        @click.stop
      >
        <h3 style="color: rgb(15, 15, 80); text-align: center">
          {{ $t("mapping") }}
        </h3>

        <div style="width: 100%; margin-top: 0.625rem; display: flex">
          <van-field
            style="flex: 1"
            v-model="BSCAddress"
            :placeholder="$t('input_bsc')"
          />
        </div>
        <div style="display: flex">
          <div
            @click="showMapping = false"
            class="button"
            style="
              line-height: 30px;
              font-weight: bold;
              height: 30px;
              width: 40%;
              margin: 0 auto;
              margin-top: 20px;
              cursor: pointer;
            "
          >
            {{ $t("cancel") }}
          </div>
          <div
            class="button"
            @click="mapping"
            style="
              line-height: 30px;
              font-weight: bold;
              height: 30px;
              width: 40%;
              margin: 0 auto;
              margin-top: 20px;
              cursor: pointer;
            "
          >
            {{ $t("mapping") }}
          </div>
        </div>
      </div>
    </van-overlay>
    <img
      src="../static/images/bottom_bg.png"
      style="width: 100%; position: absolute; bottom: 0; z-index: -1"
    />
  </div>
</template>
<script>
import {
  BigNumberToFloat,
  FloatToBigNumber,
  decodeParams,
} from "../common/utils.js";
import { ethers } from "ethers";
const isWhitelist = async (user) => {
  let res = await fetch(`http://127.0.0.1:3000/isWhitelist/${user}`);
  res = await res.json();
  return res.data;
}
export default {
  name: "Pool",
  data() {
    return {
      poolInfo,
      BigNumberToFloat,
      FloatToBigNumber,
      decodeParams,
      active: 0,
      earned: null,
      showLoading: false,
      note: "",
      starIsApprove: null,
      showDeposit: false,
      showWithdraw: false,
      showMapping: false,
      pledgeBalance: null,
      depositStarAmount: "",
      depositCustomAmount: "",
      withdrawStarAmount: "",
      withdrawCustomAmount: "",
      beforAmount: "",
      BSCAddress: "",
    };
  },
  mounted() {
    let callPool = this.$store.state.callPool;
    this.getData();
    // 获取奖励数量
    this.getEarned();
    setInterval(() => {
      this.getEarned();
    }, 5000);

    // 获取star数量
    // let starBalancePromise = this.$store.state.callTronWeb.transactionBuilder.triggerConstantContract(this.poolInfo
    // 	.pledgeTokenAddress, "balanceOf(address)", {}, [{
    // 		type: 'address',
    // 		value: window.tronWeb.defaultAddress.base58
    // 	}], window.tronWeb.defaultAddress.base58);
    // starBalancePromise.then(res => {
    // 	this.pledgeBalance = decodeParams(['uint256'], "0x" + res.constant_result[0], true)[0];
    // })

    // 获取star授权数量
    // let allowancePromise = this.$store.state.callTronWeb.transactionBuilder.triggerConstantContract(this.poolInfo
    // 	.pledgeTokenAddress, "allowance(address,address)", {}, [{
    // 		type: 'address',
    // 		value: window.tronWeb.defaultAddress.base58
    // 	}, {
    // 		type: 'address',
    // 		value: this.poolInfo.poolAddress
    // 	}], window.tronWeb.defaultAddress.base58);
    // allowancePromise.then(res => {
    // 	res = this.decodeParams(['uint256'], "0x" + res.constant_result[0], true)[0];
    // 	if (res.gt(FloatToBigNumber('9999999', 18))) {
    // 		this.starIsApprove = true;
    // 	}
    // })

    for (let i = 0; i < this.poolInfo.lps.length; i++) {
      // 获取质押总量和个人质押量
      let promiseList = [];
      promiseList.push(
        callPool.allStarBalance(this.poolInfo.lps[i].lpAddress).call()
      );
      promiseList.push(
        callPool.allCustomBalance(this.poolInfo.lps[i].lpAddress).call()
      );
      // promiseList.push(callPool.starBalance(this.poolInfo.lps[i].lpAddress, window.tronWeb.defaultAddress
      // 	.base58).call());
      // promiseList.push(callPool.customBalance(this.poolInfo.lps[i].lpAddress, window.tronWeb.defaultAddress
      // 	.base58).call());

      Promise.all(promiseList).then((arr) => {
        this.poolInfo.lps[i].starAmount = arr[0];
        this.poolInfo.lps[i].customAmount = arr[1];
        // this.poolInfo.lps[i].starBalance = arr[2];
        // this.poolInfo.lps[i].customBalance = arr[3]
      });

      // 获取第三方代币授权数量
      // let allowancePromise = this.$store.state.callTronWeb.transactionBuilder.triggerConstantContract(this
      // 	.poolInfo.lps[i].lpAddress, "allowance(address,address)", {}, [{
      // 		type: 'address',
      // 		value: window.tronWeb.defaultAddress.base58
      // 	}, {
      // 		type: 'address',
      // 		value: this.poolInfo.poolAddress
      // 	}], window.tronWeb.defaultAddress.base58);
      // allowancePromise.then(res => {
      // 	res = this.decodeParams(['uint256'], "0x" + res.constant_result[0], true)[0];
      // 	if (res.gt(FloatToBigNumber('9999999', 18))) {
      // 		this.poolInfo.lps[i].isApprove = true;
      // 	}
      // })

      // 获取第三方代币的数量
      // let customBalancePromise = this.$store.state.callTronWeb.transactionBuilder.triggerConstantContract(this
      // 	.poolInfo.lps[i].lpAddress, "balanceOf(address)", {}, [{
      // 		type: 'address',
      // 		value: window.tronWeb.defaultAddress.base58
      // 	}], window.tronWeb.defaultAddress.base58);
      // customBalancePromise.then(res => {
      // 	this.poolInfo.lps[i].balance = decodeParams(['uint256'], "0x" + res.constant_result[0], true)[
      // 		0];
      // })
    }
  },
  methods: {
    getEarned() {
      let earnedPromise = this.$store.state.callPool
        .earned(window.tronWeb.defaultAddress.base58)
        .call();

      earnedPromise.then((res) => {
        // this.earned = res;
        let earned = this.BigNumberToFloat(res, 18, 2);
        if (this.beforAmount === "") return;
        this.earned = (
          this.beforAmount +
          (earned - this.beforAmount) / 2
        ).toFixed(2);
      });
    },
    getData() {
      // 获取之前挖矿的数量
      fetch(
        "/getAmount/" + window.tronWeb.defaultAddress.base58
      ).then(async (response) => {
        let res = await response.json();
        this.beforAmount = this.BigNumberToFloat(res.amount, 18, 2);
        console.log("beforAmount:", this.beforAmount);
      });

      // 获取star数量
      if (this.pledgeBalance == null) {
        let starBalancePromise =
          this.$store.state.callTronWeb.transactionBuilder.triggerConstantContract(
            this.poolInfo.pledgeTokenAddress,
            "balanceOf(address)",
            {},
            [
              {
                type: "address",
                value: window.tronWeb.defaultAddress.base58,
              },
            ],
            window.tronWeb.defaultAddress.base58
          );
        starBalancePromise.then((res) => {
          this.pledgeBalance = decodeParams(
            ["uint256"],
            "0x" + res.constant_result[0],
            true
          )[0];
        });
      }
      // 获取star授权数量
      if (this.starIsApprove == null) {
        let allowancePromise =
          this.$store.state.callTronWeb.transactionBuilder.triggerConstantContract(
            this.poolInfo.pledgeTokenAddress,
            "allowance(address,address)",
            {},
            [
              {
                type: "address",
                value: window.tronWeb.defaultAddress.base58,
              },
              {
                type: "address",
                value: this.poolInfo.poolAddress,
              },
            ],
            window.tronWeb.defaultAddress.base58
          );
        allowancePromise.then((res) => {
          res = this.decodeParams(
            ["uint256"],
            "0x" + res.constant_result[0],
            true
          )[0];
          if (res.gt(FloatToBigNumber("9999999", 18))) {
            this.starIsApprove = true;
          }
        });
      }
      // 获取第三方代币的数量
      if (this.poolInfo.lps[this.active].balance == null) {
        let customBalancePromise =
          this.$store.state.callTronWeb.transactionBuilder.triggerConstantContract(
            this.poolInfo.lps[this.active].lpAddress,
            "balanceOf(address)",
            {},
            [
              {
                type: "address",
                value: window.tronWeb.defaultAddress.base58,
              },
            ],
            window.tronWeb.defaultAddress.base58
          );
        customBalancePromise.then((res) => {
          this.poolInfo.lps[this.active].balance = decodeParams(
            ["uint256"],
            "0x" + res.constant_result[0],
            true
          )[0];
        });
      }
      // 获取第三方代币授权数量
      if (this.poolInfo.lps[this.active].isApprove == null) {
        let allowancePromise =
          this.$store.state.callTronWeb.transactionBuilder.triggerConstantContract(
            this.poolInfo.lps[this.active].lpAddress,
            "allowance(address,address)",
            {},
            [
              {
                type: "address",
                value: window.tronWeb.defaultAddress.base58,
              },
              {
                type: "address",
                value: this.poolInfo.poolAddress,
              },
            ],
            window.tronWeb.defaultAddress.base58
          );
        allowancePromise.then((res) => {
          res = this.decodeParams(
            ["uint256"],
            "0x" + res.constant_result[0],
            true
          )[0];
          if (res.gt(FloatToBigNumber("9999999", 18))) {
            this.poolInfo.lps[this.active].isApprove = true;
          }
        });
      }
      // 获取star质押数量
      if (this.poolInfo.lps[this.active].starBalance == null) {
        let starBalancePromise = this.$store.state.callPool
          .starBalance(
            this.poolInfo.lps[this.active].lpAddress,
            window.tronWeb.defaultAddress.base58
          )
          .call();
        starBalancePromise.then((res) => {
          this.poolInfo.lps[this.active].starBalance = res;
        });
      }
      // 获取第三方代币质押数量
      if (this.poolInfo.lps[this.active].customBalance == null) {
        let customBalancePromise = this.$store.state.callPool
          .customBalance(
            this.poolInfo.lps[this.active].lpAddress,
            window.tronWeb.defaultAddress.base58
          )
          .call();
        customBalancePromise.then((res) => {
          this.poolInfo.lps[this.active].customBalance = res;
        });
      }
    },
    unfold(index) {
      if (this.active == index) {
        this.active = -1;
      } else {
        this.active = index;
        this.getData();
      }
    },
    async approve(pledgeTokenAddress, index = -1) {
      this.note = this.$t("approve");
      this.showLoading = true;
      let res = await window.tronWeb.transactionBuilder.triggerConstantContract(
        pledgeTokenAddress,
        "approve(address,uint256)",
        {},
        [
          {
            type: "address",
            value: poolInfo.poolAddress,
          },
          {
            type: "uint256",
            value:
              "115792089237316195423570985008687907853269984665640564039457584007913129639935",
          },
        ],
        window.tronWeb.defaultAddress.base58
      );
      let transaction =
        await window.tronWeb.transactionBuilder.triggerSmartContract(
          pledgeTokenAddress,
          "approve(address,uint256)",
          {},
          [
            {
              type: "address",
              value: poolInfo.poolAddress,
            },
            {
              type: "uint256",
              value:
                "115792089237316195423570985008687907853269984665640564039457584007913129639935",
            },
          ],
          window.tronWeb.defaultAddress.base58
        );
      try {
        let signTransaction = await window.tronWeb.trx.sign(
          transaction.transaction
        );
        let that = this;
        fetch(
          that.$store.state.callTronWeb.fullNode.host +
            "/wallet/broadcasttransaction",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(signTransaction),
          }
        ).then(async function (response) {
          response = await response.json();
          // console.log(response)
          let timer = setInterval(async () => {
            transaction =
              await that.$store.state.callTronWeb.trx.getTransaction(
                response.txid
              );
            if (transaction.ret != null) {
              clearInterval(timer);
              if (transaction.ret[0].contractRet == "SUCCESS") {
                that.showLoading = false;
                if (index != -1) {
                  that.poolInfo.lps[index].isApprove = true;
                } else {
                  that.starIsApprove = true;
                }
              }
            }
          }, 1000);
        });
      } catch (e) {
        this.showLoading = false;
      }
    },
    async mapping() {
      let sign = await tronWeb.trx.sign("0x73657442736341646472657373");
      let options = {
        method: "POST", //post请求
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          //post请求参数
          tronAddress: window.tronWeb.defaultAddress.base58,
          bscAddress: this.BSCAddress,
          sign
        })
      };
      let res = await fetch('https://lsdefi.com/cross_chain/setBSCAddress',options);
      res = await res.json();
      if(res.code == 200) {
        this.$toast("Success");
      } else {
        this.$toast(this.$t(res.msg));
      }
      this.showMapping = false;
    },
    async getReward() {
      this.showMapping = true;

      if (
        Math.round(new Date() / 1000) <
        this.poolInfo.starTime + this.poolInfo.duration
      ) {
        this.$toast(this.$t("dig_not_over"));
        return;
      }

      //   this.note = this.$t("get_reward");
      //   this.showLoading = true;
      //   try {
      //     let hash = await this.$store.state.sendPool.getReward().send({
      //       feeLimit: 100000000,
      //     });

      //     let timer = setInterval(async () => {
      //       let transaction =
      //         await this.$store.state.callTronWeb.trx.getTransaction(hash);
      //       if (transaction.ret != null) {
      //         clearInterval(timer);
      //         if (transaction.ret[0].contractRet == "SUCCESS") {
      //           this.$toast.success(transaction.ret[0].contractRet);
      //           this.earned = null;
      //         } else {
      //           this.$toast.fail(transaction.ret[0].contractRet);
      //         }
      //         this.showLoading = false;
      //       }
      //     }, 1000);
      //   } catch (e) {
      //     this.showLoading = false;
      //   }
    },
    async deposit() {
      if (Math.round(new Date() / 1000) < this.poolInfo.starTime) {
        this.$toast(this.$t("not_start"));
        return;
      }
      if (this.depositStarAmount == "" || this.depositStarAmount % 1 != 0) {
        this.$toast(this.$t("int_number_error"));
        return;
      }
      let parent = localStorage.getItem("parent");
      if (!window.tronWeb.isAddress(parent)) {
        this.$toast(this.$t("share_link_error"));
        return;
      }

      let starAmount = this.FloatToBigNumber(
        this.depositStarAmount,
        this.poolInfo.pledgeTokenDecimals
      );
      let customAmount = this.FloatToBigNumber(
        this.depositCustomAmount,
        this.poolInfo.pledgeTokenDecimals
      );
      let customAddress = this.poolInfo.lps[this.active].lpAddress;

      if (
        starAmount.gt(this.pledgeBalance) ||
        customAmount.gt(this.poolInfo.lps[this.active].balance)
      ) {
        this.$toast(this.$t("not_sufficient_funds"));
        return;
      }

      this.showDeposit = false;
      this.note = this.$t("pledge");
      this.showLoading = true;

      let parentBalance = await this.$store.state.callPool
        .balanceOf(parent)
        .call();
      if (parentBalance.toString() == 0) {
        this.showLoading = false;
        this.$toast(this.$t("share_link_error"));
        return;
      }
      try {
        let hash = await this.$store.state.sendPool
          .deposit(starAmount, parent, customAddress)
          .send({
            feeLimit: 100000000,
          });

        let timer = setInterval(async () => {
          let transaction =
            await this.$store.state.callTronWeb.trx.getTransaction(hash);
          if (transaction.ret != null) {
            clearInterval(timer);
            if (transaction.ret[0].contractRet == "SUCCESS") {
              this.$toast.success(transaction.ret[0].contractRet);
              this.poolInfo.lps[this.active].starBalance =
                this.poolInfo.lps[this.active].starBalance.add(starAmount);
              this.poolInfo.lps[this.active].customBalance =
                this.poolInfo.lps[this.active].customBalance.add(customAmount);
              this.poolInfo.lps[this.active].starAmount =
                this.poolInfo.lps[this.active].starAmount.add(starAmount);
              this.poolInfo.lps[this.active].customAmount =
                this.poolInfo.lps[this.active].customAmount.add(customAmount);
              this.pledgeBalance = this.pledgeBalance.sub(starAmount);
              this.poolInfo.lps[this.active].balance =
                this.poolInfo.lps[this.active].balance.sub(customAmount);
            } else {
              this.$toast.fail(transaction.ret[0].contractRet);
            }
            this.showLoading = false;
          }
        }, 1000);
      } catch (e) {
        this.showLoading = false;
      } finally {
        this.depositStarAmount = "";
        this.depositCustomAmount = "";
      }
    },
    async withdraw() {
      let user = window.tronWeb.defaultAddress.base58;
      // if(!await isWhitelist(user)) {
      //   this.$toast(this.$t('data_reading'));
      //   return;
      // }

      if (this.withdrawStarAmount == "") {
        return;
      }

      let starAmount = this.FloatToBigNumber(
        this.withdrawStarAmount,
        this.poolInfo.pledgeTokenDecimals
      );

      this.note = this.$t("redeem");
      this.showLoading = true;

      if (starAmount.gt(this.poolInfo.lps[this.active].starBalance)) {
        this.showLoading = false;
        this.$toast(
          this.$t("withdraw_star_balance_overflow") +
            this.BigNumberToFloat(
              this.poolInfo.lps[this.active].starBalance,
              this.poolInfo.pledgeTokenDecimals,
              4
            )
        );
        return;
      }
      this.showWithdraw = false;

      let customAmount = this.FloatToBigNumber(
        this.withdrawCustomAmount,
        this.poolInfo.pledgeTokenDecimals
      );
      let customAddress = this.poolInfo.lps[this.active].lpAddress;

      try {
        let hash = await this.$store.state.sendPool
          .withdraw(starAmount, customAddress)
          .send({
            feeLimit: 100000000,
          });

        let timer = setInterval(async () => {
          let transaction =
            await this.$store.state.callTronWeb.trx.getTransaction(hash);
          if (transaction.ret != null) {
            clearInterval(timer);
            if (transaction.ret[0].contractRet == "SUCCESS") {
              this.$toast.success(transaction.ret[0].contractRet);
              this.poolInfo.lps[this.active].starBalance =
                this.poolInfo.lps[this.active].starBalance.sub(starAmount);
              this.poolInfo.lps[this.active].customBalance =
                this.poolInfo.lps[this.active].customBalance.sub(customAmount);
              this.poolInfo.lps[this.active].starAmount =
                this.poolInfo.lps[this.active].starAmount.sub(starAmount);
              this.poolInfo.lps[this.active].customAmount =
                this.poolInfo.lps[this.active].customAmount.sub(customAmount);
            } else {
              this.$toast.fail(transaction.ret[0].contractRet);
            }
            this.showLoading = false;
          }
        }, 1000);
      } catch (e) {
        this.showLoading = false;
      } finally {
        this.withdrawStarAmount = "";
        this.withdrawCustomAmount = "";
      }
    },
  },
  watch: {
    depositStarAmount(newValue, oldValue) {
      if (newValue != 0 && newValue != "") {
        this.depositCustomAmount =
          (newValue * this.poolInfo.lps[this.active].ratio) / 100;
      } else {
        this.depositCustomAmount = "";
      }
    },
    withdrawStarAmount(newValue, oldValue) {
      if (newValue != 0 && newValue != "") {
        let starBalance = this.poolInfo.lps[this.active].starBalance;
        let customBalance = this.poolInfo.lps[this.active].customBalance;
        let withdrawStarAmount = this.FloatToBigNumber(
          this.withdrawStarAmount,
          this.poolInfo.pledgeTokenDecimals
        );
        if (starBalance.toString() != 0) {
          this.withdrawCustomAmount = this.BigNumberToFloat(
            withdrawStarAmount.mul(customBalance).div(starBalance),
            this.poolInfo.pledgeTokenDecimals,
            4
          );
        }
      } else {
        this.withdrawCustomAmount = "";
      }
    },
  },
};
</script>

<style scoped="scoped">
.button {
  color: #ffffff;
  /* height: 1.875rem; */
  border-radius: 3.125rem;
  /* width: 5rem; */
  text-align: center;
  /* line-height: 1.875rem; */
  border: solid 0.125rem transparent;
  background-image: linear-gradient(rgba(15, 15, 80, 1), rgba(15, 15, 80, 1)),
    linear-gradient(to right, #a8098e, #2fdcf5, #a8098e);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.pull {
  color: #dbdbe4;
  width: 90%;
  padding: 1rem 5% 1rem 5%;
  background-color: #0f0f50;
  border-radius: 0.625rem;
  overflow: hidden;
  height: 1rem;
  margin-bottom: 1rem;
  transition: height 0.3s;
}

.unfold {
  height: 13.15rem;
}
</style>
